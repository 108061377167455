/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { NavLink } from "react-router-dom";
import img3 from "../../img/legal.jpg";
import about3 from "../../img/b8.png";
import { Container, Row, Col } from "react-bootstrap";
function Legal() {
  return (
    <div>
      <Container className="px-3">
        <img className="d-block w-100" src={about3} alt="Slider Image" />
      </Container>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container className="pt-5 pb-5">
          <Row>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <h2 className="about-text" style={{ color: "#00acdd" }}>
                Legal Consultancy
              </h2>
              <div className="-text-container">
                <p className="text-justify">
                  Welcome to Jansons Group, your trusted destination for
                  comprehensive legal consultancy and top-notch legal services.
                  We take pride in offering a wide range of legal solutions
                  through our dedicated platform, The Case Lawyer.
                </p>
                <p className="text-justify">
                  At Jansons Group, we understand the complex and ever-changing
                  nature of the legal landscape. That's why we have partnered
                  with The Case Lawyer, a team of experienced and skilled legal
                  professionals, to provide you with expert advice and tailored
                  legal services to meet your specific needs.
                </p>
                <p className="text-justify">
                  Our legal consultancy services encompass a broad spectrum of
                  practice areas, including but not limited to corporate law,
                  contract law, intellectual property, employment law, real
                  estate law, and dispute resolution. Whether you are an
                  individual, a small business owner, or a large corporation,
                  our team is equipped with the knowledge and expertise to guide
                  you through legal complexities and help you achieve your
                  desired outcomes.
                </p>
                <p className="text-justify">
                  Explore our website to learn more about the legal consultancy
                  and services offered by The Case Lawyer, and discover how we
                  can assist you in navigating the complex legal landscape. Let
                  us be your trusted partner in legal matters, providing you
                  with the guidance and representation you need to achieve the
                  best possible outcomes. Experience the difference of working
                  with a team that is passionate about protecting your rights
                  and helping you find effective legal solutions.
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="text-center">
        <Container className="pt-5 pb-5">
          <Container className="text-center">
            <Row>
              <Col xs={12} sm={12} md={4} lg={4} xl={3} className="mb-3">
                <div
                  className="card"
                  style={{
                    border: "none",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <NavLink
                    to="https://thecaselawyer.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="card-img-top"
                      style={{
                        width: "16rem",
                        height: "16rem",
                        borderRadius: "10px",
                      }}
                      src={img3}
                      alt="Description of the image"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: "0",
                        padding: "0",
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        textAlign: "center",
                      }}
                    >
                      <h3
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "0",
                          fontSize: "1.9rem" /* default font size */,
                          lineHeight: "2rem" /* default line height */,
                        }}
                      >
                        The Case Lawyer
                      </h3>
                    </div>
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </div>
  );
}

export default Legal;
