/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { NavLink } from "react-router-dom";
import { Container,Col, Row } from "react-bootstrap";
import about3 from "../../img/socialmedia.jpg";
import about4 from "../../img/digi.jpg";
function DigitalMedia() {
  return (
    <div>
      {" "}
      <Container className="px-3">
        <img className="d-block w-100" src={about3} alt="Slider Image" />
      </Container>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container className="pt-5 pb-5">
          <Row>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <h2 className="about-text" style={{ color: "#00acdd" }}>
               Digital Media
              </h2>
              <div className="-text-container">
                <p className="text-justify">
                Welcome to Jansons Group, your gateway to the world of digital media. As a dynamic and forward-thinking company, we specialize in delivering exceptional digital media services to meet the evolving needs of businesses and individuals. Through our website, Jansons Group offers a comprehensive range of digital media solutions designed to enhance your online presence and drive success in the digital landscape.
                </p>
                <p className="text-justify">
                In today's fast-paced digital age, a strong online presence is essential for businesses to thrive. At Jansons Group, we understand the power of digital media and its impact on brand visibility, customer engagement, and business growth. Our team of digital media experts is committed to helping you navigate the digital landscape and harness its full potential.
                </p>
                <p className="text-justify">
                At Jansons Group, we pride ourselves on our dedication to delivering excellence in digital media services. We strive to go beyond expectations, providing a seamless and personalized experience for each client. Our focus is on building long-term partnerships, fostering growth, and maximizing the digital potential of your business.
                </p>
                <p className="text-justify">
                Whether you are a small start-up, a growing enterprise, or an individual looking to establish a strong online presence, Jansons Group is your trusted partner in the digital world. Visit our website to explore our range of digital media services and let us help you unlock the power of digital media to propel your business to new heights. Experience the difference of working with a team that is passionate about digital innovation and dedicated to your success.
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="text-center">
        <Container className="pt-5 pb-5">
          <Container className="text-center">
            <Row>
              <Col xs={12} sm={12} md={4} lg={4} xl={3} className="mb-3">
                <div
                  className="card"
                  style={{
                    border: "none",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <NavLink
                    to="https://thedigimedia.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="card-img-top"
                      style={{
                        width: "16rem",
                        height: "16rem",
                        borderRadius: "10px",
                      }}
                      src={about4}
                      alt="Description of the image"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: "0",
                        padding: "0",
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        textAlign: "center",
                      }}
                    >
                      <h3
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "0",
                          fontSize: "1.9rem" /* default font size */,
                          lineHeight: "2rem" /* default line height */,
                        }}
                      >
                        DigiMedia Services
                      </h3>
                    </div>
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </div>
  );
}

export default DigitalMedia;
