import React from "react";
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/footer/footer";
import Home from "./Components/HomePage/Home";
import Mission from "./Components/Pages/Mission";
import Culture from "./Components/Pages/Culture";
import Property from "./Components/Pages/property";
import AboutUs from "./Components/Pages/AboutUs";
import Media from "./Components/Pages/media";
import Travel from "./Components/Pages/Travel";
import Legal from "./Components/Pages/Legal";
import Carrer from "./Components/Pages/Carrer";
import Removal from "./Components/Pages/Removal";
import DigitalMedia from "./Components/Pages/DigitalMedia";
import Overseas from "./Components/Pages/Overseas";
import BusinessProcess from "./Components/Pages/BusinessProcess";
import Pakistani from "./Components/Pages/pakistani";

function App() {
  return (
    <BrowserRouter>
    <React.Fragment>
      <Navbar />
      <Routes> 
        <Route path="/" element={<Home />} />
        <Route path="/about-the-group" element={<AboutUs/>} />
        <Route path="/property-development" element={<Property/>} />
        <Route path="/media" element={<Media/>} />
        <Route path="/carrer" element={<Carrer/>} />
        <Route path="/mission-and-vision" element={<Mission/>} />
        <Route path="/value-and-culture" element={<Culture/>} />
        <Route path="/travel-and-tourism" element={<Travel/>} />
        <Route path="/legal-consultancy" element={<Legal/>} />
        <Route path="/removal" element={<Removal/>} />
        <Route path="/digital-media" element={<DigitalMedia/>} />
        <Route path="/legal-foundation-for-overseas-pakistani" element={<Overseas/>} />
        <Route path="/Business-Process-Outsourcing" element={<BusinessProcess/>} />
        <Route path="/overseas-pakistanis-legal-services-international" element={<Pakistani/>} />
      </Routes>
      <Footer />
    </React.Fragment>
  </BrowserRouter>
   
  );
}

export default App;
