/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Container, Row } from "react-bootstrap";
import about3 from "../../img/b10.jpg";
function Overseas() {
  return (
    <div>
      {" "}
      <Container className="px-3">
        <img className="d-block w-100" src={about3} alt="Slider Image" />
      </Container>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container className="pt-5 pb-5">
          <Row>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <h2 className="about-text" style={{ color: "#00acdd" }}>
                Jansons Legal Foundation For Overseas Pakistani's
              </h2>
              <div className="-text-container">
                <p className="text-justify">
                  Welcome to Jansons Group, your reliable source for legal
                  foundation and support for overseas Pakistanis. We understand
                  the unique challenges and concerns faced by Pakistani
                  expatriates residing abroad, and through our website, we offer
                  comprehensive legal services to address their needs and ensure
                  a strong legal foundation.
                </p>
                <p className="text-justify">
                  At Jansons Group, we recognize the importance of providing
                  overseas Pakistanis with access to legal expertise and
                  guidance. We are committed to assisting you in navigating
                  legal complexities, protecting your rights, and ensuring your
                  interests are safeguarded. Our team of experienced legal
                  professionals specializes in matters relevant to overseas
                  Pakistanis, including immigration, citizenship, property
                  ownership, inheritance, and business investments.
                </p>
                <p className="text-justify">
                  Jansons Group takes pride in its commitment to excellence,
                  transparency, and client satisfaction. We believe in building
                  long-term relationships with our clients, earning their trust
                  through our professionalism and dedication to their legal
                  needs. Our team is well-versed in the cultural sensitivities
                  and unique challenges faced by overseas Pakistanis, ensuring
                  that you receive personalized and empathetic legal support.{" "}
                </p>
               
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Overseas;
