/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import AboutText from "../text/aboutText";
import about from "../../img/b1.png"
import { Container} from "react-bootstrap";

function AboutUs() {
  return (
    <div>
       <Container className="px-3">
        <img className="d-block w-100" src={about} alt="Slider Image" />
      </Container>
      <AboutText/>
    </div>
  );
}

export default AboutUs;
