import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import p1 from "../../img/b1.png";
import p2 from "../../img/b2.png";
import p3 from "../../img/b3.png";
import p4 from "../../img/b4.png";
import p5 from "../../img/b5.png";
import p6 from "../../img/b6.png";
import p7 from "../../img/b7.png";
import p8 from "../../img/b8.png";


const Slider = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const images = [p1, p2,p3,p4,p5,p6,p7,p8];

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((imageIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [imageIndex, images.length]);

  return (
    <Container className="px-5">
      <img
        className="d-block w-100"
        src={images[imageIndex]}
        alt="Slider Image"
      />
    </Container>
  );
};

export default Slider;
