/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Container, Row } from "react-bootstrap";
import about1 from "../../img/b3.png";
function Mission() {
  return (
    <div>
      {" "}
      <Container className="px-3">
        <img className="d-block w-100" src={about1} alt="Slider Image" />
      </Container>
      <Container className="pt-5 ">
        <Row>
          <div
            data-aos="fade-up"
            data-aos-duration="3000"
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          >
            <h2 className="about-text" style={{ color: "#00acdd" }}>
              Mission & Vision
            </h2>
            <h6 style={{ color: "#00acdd" }}> Our Vision</h6>
            <div className="-text-container">
              <p className="text-justify">
                At Jansons Group, our vision is to be a trusted and
                transformative partner, driving positive change and innovation
                across diverse industries. Through our website, we aim to
                provide exceptional services and solutions that meet the
                evolving needs of our clients, enabling them to achieve their
                goals and thrive in a dynamic business environment.
              </p>
            </div>
            <h6 style={{ color: "#00acdd" }}>Our Mission</h6>
            <p className="text-justify">
              At Jansons Group, our mission is to empower individuals and
              businesses by providing exceptional services and solutions that
              meet their diverse needs. We are committed to being a trusted
              partner and a catalyst for success in the industries we serve.
            </p>
            <p className="text-justify">
              {" "}
              Our mission begins with a deep understanding of our clients' goals
              and challenges. We believe in actively listening to their needs,
              aspirations, and concerns to tailor our services accordingly.
              Whether it's finding the perfect property, planning a memorable
              trip, obtaining legal advice, streamlining business processes, or
              addressing the unique requirements of overseas Pakistanis, our
              mission is to provide comprehensive solutions that exceed
              expectations.
            </p>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Mission;
