/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Container, Row } from "react-bootstrap";
import about2 from "../../img/b2.png";
function Culture() {
  return (
    <div>
      <Container className="px-3">
        <img className="d-block w-100" src={about2} alt="Slider Image" />
      </Container>
      <Container className="pt-5 ">
        <Row>
          <div
            data-aos="fade-up"
            data-aos-duration="3000"
            className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          >
            <h2 className="about-text" style={{color:"#00acdd"}}>Values & Culture</h2>
            <div className="-text-container">
              <h6 className="text-justify" style={{color:"#00acdd"}}>Believe</h6>
              <p className="text-justify">
                Jansons Group believes in the betterment of every employee – our
                work culture embraces everyone and offers equal opportunities to
                further capabilities and character.
              </p>
              <h6 className="text-justify" style={{color:"#00acdd"}}>Growth and Development for all</h6>
              <p className="text-justify">
                Those who find themselves working with us will find that
                competence and merit is the only basis for job security. Our
                people blossom in an environment that is filled with
                opportunities to learn and align with the world’s technological
                trends.
              </p>
              <h6 className="text-justify" style={{color:"#00acdd"}}>Recognition</h6>
              <p className="text-justify">
                When the standard is high, the drive to achieve is even higher.
                We offer performance-based evaluation, incentives and
                appreciation.
              </p>
              <h6 className="text-justify" style={{color:"#00acdd"}}>Innovation</h6>
              <p className="text-justify">
                We believe that interaction is two-way and encourage everyone to
                participate in a conversation. The spirit of enterprise and
                initiative is always considered welcome.
              </p>
              <h6 className="text-justify" style={{color:"#00acdd"}}>Trust</h6>
              <p className="text-justify">
                With openness comes trust – our team cooperates and supports
                each other with a sense of ownership and a healthy respect for
                differing opinions. Candidness is a virtue that doesn’t go
                unrecognized.
              </p>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Culture;
