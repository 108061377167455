import React from "react";
import Slider from "../Slider/Slider";

import HomeText from "../text/homeText";
import Cards from "../Cards/Cards";

function Home() {
  return (
    <div>
      <Slider />
      <HomeText />
      <Cards />
    </div>
  );
}

export default Home;
