import React from "react";
import { AiOutlineArrowUp } from "react-icons/ai";
import "./Footer.css";
import { NavLink } from "react-router-dom";

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div class="container mt-3">
          <footer style={{ backgroundColor: "#53585C" }}>
            <div class="container pt-5 ">
              <div class="row">
                <div class="col-lg-4 col-md-6 mb-4">
                  <h5
                    class="mb-2"
                    style={{ color: "#00acdd", fontSize: "25px" }}
                  >
                    About Us
                  </h5>
                  <ul class="list-unstyled mb-0">
                    <li class="mb-2">
                      <NavLink
                        to="/about-the-group"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        About The Group
                      </NavLink>
                    </li>
                    <li class="mb-2">
                      <NavLink
                        to="/mission-and-vision"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Mission & Vision
                      </NavLink>
                    </li>
                    <li class="mb-2">
                      <NavLink
                        to="/value-and-culture"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Value & Culture
                      </NavLink>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-4 col-md-6 mb-4">
                  <h5
                    class="mb-2"
                    style={{ color: "#00acdd", fontSize: "25px" }}
                  >
                   Our Business
                  </h5>
                  <ul class="list-unstyled mb-0">
                    <li class="mb-2">
                      <NavLink
                        to="/property-development"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Property & Land
                      </NavLink>
                    </li>
                    <li class="mb-2">
                      <NavLink
                        to="/travel-and-tourism"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Travel & Tourism
                      </NavLink>
                    </li>
                    <li class="mb-2">
                      <NavLink
                        to="/legal-consultancy"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Legal Consultancy
                      </NavLink>
                    </li>
                    <li class="mb-2">
                      <NavLink
                        to="/removal"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Removals
                      </NavLink>
                    </li>
                    <li class="mb-2">
                      <NavLink
                        to="/digital-media"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Digital Media
                      </NavLink>
                    </li>{" "}
                    <li class="mb-2">
                      <NavLink
                        to="/legal-foundation-for-overseas-pakistani"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Jansons Legal Foundation
                      </NavLink>
                    </li>
                    <li class="mb-2">
                      <NavLink
                        to="/Business-Process-Outsourcing"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Business Process Outsourcing
                      </NavLink>
                    </li>
                    <li class="mb-2">
                      <NavLink
                        to="/overseas-pakistanis-legal-services-international"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                                          Pakistanis Legal Services

                      </NavLink>
                    </li>
                  </ul>
                </div>

                <div class="col-lg-4 col-md-6 mb-4">
                  <h5
                    class="mb-2"
                    style={{ color: "#00acdd", fontSize: "25px" }}
                  >
                    Our Websites
                  </h5>
                  <ul className="list-unstyled">
                    <li class="mb-2" style={{ color: "#afafaf" }}>
                      <a
                        href="https://getmovers.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        GetMovers
                      </a>
                    </li>
                    <li class="mb-2" style={{ color: "#afafaf" }}>
                      <a
                        href="https://kingstontravel.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Kingston Travel
                      </a>
                    </li>
                    <li class="mb-2" style={{ color: "#afafaf" }}>
                      <a
                        href="https://skyworldtravel.uk/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Skyworld Travel
                      </a>
                    </li>
                    <li class="mb-2" style={{ color: "white" }}>
                      <a
                        href="https://alfalah.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Alfalah Travel
                      </a>
                    </li>
                    <li class="mb-2" style={{ color: "white" }}>
                      <a
                        href="https://premierlocations.co.uk/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Premier Locations
                      </a>
                    </li>
                    <li class="mb-2" style={{ color: "white" }}>
                      <a
                        href="https://primelocations.com.pk/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        Prime Locations
                      </a>
                    </li>

                    <li class="mb-2" style={{ color: "white" }}>
                      <a
                        href="https://thecaselawyer.com/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#afafaf" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#afafaf")}
                      >
                        The Case Lawyer
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <footer
              class="bg-grey text-center  text-lg-start"
              style={{
                display: "flex",
                borderTop: "1px solid rgba(255, 255, 255, 0.25)",
                justifyContent: "space-around",
              }}
            >
              <p className=" text-center footer-border pt-3 ">
                © Copyright by Jansons Group Of Companies. All Rights Reserved.
              </p>
              <button
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                style={{
                  background: "none",
                  fontSize: "30px",
                  color: "#afafaf",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                <AiOutlineArrowUp />
              </button>
            </footer>
          </footer>
        </div>
      </div>
    );
  }
}

export default Footer;
