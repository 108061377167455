/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { NavLink } from "react-router-dom";
import img1 from "../../img/pre.jpg";
import img2 from "../../img/prii.jpg";
import about3 from "../../img/b4.png";

import { Container, Row, Col } from "react-bootstrap";
function Property() {
  return (
    <div>
      <Container className="px-3">
        <img className="d-block w-100" src={about3} alt="Slider Image" />
      </Container>

      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container className="pt-5 pb-5">
          <Row>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <h2 className="about-text" style={{ color: "#00acdd" }}>
                Property Development
              </h2>
              <div className="-text-container">
                <p className="text-justify">
                  Welcome to Jansons Group, your ultimate destination for
                  premier properties in prime locations. As a leading provider
                  of real estate services, we specialize in offering an
                  exceptional selection of properties in the most sought-after
                  areas.
                </p>
                <p className="text-justify">
                  At Jansons Group, we understand that location is a crucial
                  factor when it comes to finding the perfect property. That's
                  why we meticulously curate a portfolio of prime properties
                  situated in highly desirable neighborhoods and thriving
                  business districts. Whether you are seeking a luxurious
                  residential property or a commercial space to expand your
                  business, we have an extensive range of options to cater to
                  your specific needs.
                </p>
                <p className="text-justify">
                  Jansons Group takes pride in its commitment to excellence, and
                  we strive to exceed your expectations at every step. From
                  conducting thorough due diligence to negotiating favorable
                  terms, our goal is to ensure a seamless and successful
                  transaction. We prioritize transparency, integrity, and
                  professionalism in all our dealings, building long-lasting
                  relationships with our clients based on trust and reliability.
                </p>
                <p className="text-justify">
                  Whether you are looking to invest in a prestigious property or
                  establish your business in a prime location, Jansons Group is
                  your trusted partner. Explore our website to discover the
                  finest selection of properties in premier locations, and let
                  us assist you in making your real estate dreams a reality.
                  Experience the difference of working with a team that is
                  passionate about delivering outstanding results and helping
                  you unlock the potential of prime real estate.
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </div>

      <div className="text-center">
        <Container className="pt-5 pb-5">
          <Container className="text-center">
            <Row>
              <Col xs={12} sm={12} md={4} lg={4} xl={3} className="mb-3">
                <div
                  className="card"
                  style={{
                    border: "none",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <NavLink
                    to="https://premierlocations.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="card-img-top"
                      style={{
                        width: "16rem",
                        height: "16rem",
                        borderRadius: "10px",
                      }}
                      src={img1}
                      alt="Description of the image"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: "0",
                        padding: "0",
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        textAlign: "center",
                      }}
                    >
                      <h3
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "0",
                          fontSize: "1.9rem" /* default font size */,
                          lineHeight: "2rem" /* default line height */,
                        }}
                      >
                        Premier Locations
                      </h3>
                    </div>
                  </NavLink>
                </div>
              </Col>

              <Col xs={12} sm={12} md={4} lg={4} xl={3} className="mb-3">
                <div
                  className="card"
                  style={{
                    border: "none",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <NavLink
                    to="https://primelocations.com.pk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="card-img-top"
                      style={{
                        width: "16rem",
                        height: "16rem",
                        borderRadius: "10px",
                      }}
                      src={img2}
                      alt="Description of the image"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: "0",
                        padding: "0",
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        textAlign: "center",
                      }}
                    >
                      <h3
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "0",
                          fontSize: "1.9rem" /* default font size */,
                          lineHeight: "2rem" /* default line height */,
                        }}
                      >
                        Prime Locations
                      </h3>
                    </div>
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </div>
  );
}

export default Property;
