/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { NavLink } from "react-router-dom";
import img1 from "../../img/king.jpg";
import img2 from "../../img/sky.jpg";
import img3 from "../../img/hajj.jpg";
import about3 from "../../img/b1.png";
import "./Pages.css";

import { Container, Row, Col } from "react-bootstrap";
function Travel() {
  return (
    <div>
      <Container className="px-3">
        <img className="d-block w-100" src={about3} alt="Slider Image" />
      </Container>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container className="pt-5 pb-5">
          <Row>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <h2 className="about-text" style={{ color: "#00acdd" }}>
                Travel & Tourism
              </h2>
              <div className="-text-container">
                <p className="text-justify">
                  Welcome to Jansons Group, your one-stop destination for
                  exceptional travel and tourism experiences. We are proud to
                  offer a wide range of services that cater to your travel
                  needs, including the provision of Hajj and Umrah services
                  through our esteemed partners: Kingstone Travel, Skyworld
                  Travels International, and Alfalah Hajj Umrah.
                </p>
                <p className="text-justify">
                  At Jansons Group, we understand that travel is not just about
                  reaching a destination; it's about creating lifelong memories
                  and enriching experiences. Whether you are planning a
                  leisurely vacation, a business trip, or a spiritual journey to
                  the holy cities of Makkah and Madinah, we are here to make
                  your travel dreams come true.
                </p>
                <p className="text-justify">
                  Through our trusted partners, Kingstone Travel, Skyworld
                  Travels International, and Alfalah Hajj Umrah, we provide
                  top-notch Hajj and Umrah services. We understand the
                  importance and significance of these sacred journeys, and we
                  strive to ensure that every aspect of your pilgrimage is
                  meticulously planned and executed. From visa processing and
                  flight arrangements to accommodation and transportation, our
                  dedicated team will handle all the necessary arrangements,
                  allowing you to focus on your spiritual journey with peace of
                  mind.
                </p>
                <p className="text-justify">
                  Experience the world with Jansons Group and our trusted
                  partners, Kingstone Travel, Skyworld Travels International,
                  and Alfalah Hajj Umrah. Let us take care of the details while
                  you focus on making memories that will last a lifetime.
                  Explore our website to learn more about our travel and tourism
                  services, and embark on a journey filled with wonder,
                  discovery, and spiritual fulfillment.
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="text-center">
        <Container className="pt-5 pb-5">
          <Container className="text-center">
            <Row>
              <Col xs={12} sm={12} md={4} lg={4} xl={3} className="mb-3">
                <div
                  className="card"
                  style={{
                    border: "none",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <NavLink
                    to="https://kingstontravel.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="card-img-top"
                      style={{
                        width: "16rem",
                        height: "16rem",
                        borderRadius: "10px",
                      }}
                      src={img1}
                      alt="Description of the image"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: "0",
                        padding: "0",
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        textAlign: "center",
                      }}
                    >
                      <h3
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "0",
                          fontSize: "2rem" /* default font size */,
                          lineHeight: "2rem" /* default line height */,
                        }}
                      >
                        Kingston
                      </h3>
                      <h4
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "0",
                          whiteSpace: "nowrap",
                          fontSize: "1rem" /* default font size */,
                          lineHeight: "1.2rem" /* default line height */,
                        }}
                      >
                        Travel & Tours
                      </h4>
                    </div>
                  </NavLink>
                </div>
              </Col>

              <Col xs={12} sm={12} md={4} lg={4} xl={3} className="mb-3">
                <div
                  className="card"
                  style={{
                    border: "none",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <NavLink
                    to="https://skyworldtravel.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="card-img-top"
                      style={{
                        width: "16rem",
                        height: "16rem",
                        borderRadius: "10px",
                      }}
                      src={img2}
                      alt="Description of the image"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: "0",
                        padding: "0",
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        textAlign: "center",
                      }}
                    >
                      <h3
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "0",
                          fontSize: "2rem" /* default font size */,
                          lineHeight: "2rem" /* default line height */,
                        }}
                      >
                        Skyworld Travel
                      </h3>
                      <h4
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "0",
                          whiteSpace: "nowrap",
                          fontSize: "1rem" /* default font size */,
                          lineHeight: "1.2rem" /* default line height */,
                        }}
                      >
                        International
                      </h4>
                    </div>
                  </NavLink>
                </div>
              </Col>

              <Col xs={12} sm={12} md={4} lg={4} xl={3} className="mb-3">
                <div
                  className="card"
                  style={{
                    border: "none",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <NavLink
                    to="https://alfalah.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="card-img-top"
                      style={{
                        width: "16rem",
                        height: "16rem",
                        borderRadius: "10px",
                      }}
                      src={img3}
                      alt="Description of the image"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: "0",
                        padding: "0",
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        textAlign: "center",
                      }}
                    >
                      <h3
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "0",
                          fontSize: "2rem" /* default font size */,
                          lineHeight: "2rem" /* default line height */,
                        }}
                      >
                        Alfalah
                      </h3>
                      <h4
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "0",
                          whiteSpace: "nowrap",
                          fontSize: "1rem" /* default font size */,
                          lineHeight: "1.2rem" /* default line height */,
                        }}
                      >
                        Hajj , Umrah
                      </h4>
                    </div>
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </div>
  );
}

export default Travel;
