/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Container, Row } from "react-bootstrap";
import about3 from "../../img/b9.jpg";
function Pakistani() {
  return (
    <div>
      {" "}
      <Container className="px-3">
        <img className="d-block w-100" src={about3} alt="Slider Image" />
      </Container>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container className="pt-5 pb-5">
          <Row>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <h2 className="about-text" style={{ color: "#00acdd" }}>
                Overseas Pakistanis Legal Services International
              </h2>
              <div className="-text-container">
                <p className="text-justify">
                  Welcome to Jansons Group, your trusted source for Overseas
                  Pakistanis Legal Services International. We understand the
                  unique legal needs and challenges faced by Pakistanis residing
                  abroad, and through our website, we offer comprehensive legal
                  services tailored specifically to meet the requirements of
                  overseas Pakistanis.
                </p>
                <p className="text-justify">
                  Jansons Group takes pride in its commitment to excellence,
                  transparency, and client satisfaction. We understand the
                  importance of building trust with our clients, and we strive
                  to provide personalized and empathetic legal support. Our team
                  is well-versed in the cultural sensitivities and unique
                  challenges faced by overseas Pakistanis, ensuring that you
                  receive the highest level of professionalism and support.
                </p>
                <p className="text-justify">
                  At Jansons Group, we are committed to empowering overseas
                  Pakistanis by providing them with comprehensive legal
                  services. We understand the importance of protecting your
                  rights, securing your investments, and ensuring your legal
                  needs are met. Visit our website to learn more about our
                  Overseas Pakistanis Legal Services International and let us be
                  your trusted partner in navigating the legal landscape,
                  ensuring your peace of mind and legal security.
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Pakistani;
