/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { NavLink } from "react-router-dom";
import img1 from "../../img/tipp.png";
import about3 from "../../img/5.png";
import { Container, Row, Col } from "react-bootstrap";
function Removal() {
  return (
    <div>
      <Container className="px-3">
        <img className="d-block w-100" src={about3} alt="Slider Image" />
      </Container>
      <div style={{ backgroundColor: "#f2f2f2" }}>
        <Container className="pt-5 pb-5">
          <Row>
            <div
              data-aos="fade-up"
              data-aos-duration="3000"
              className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            >
              <h2 className="about-text" style={{ color: "#00acdd" }}>
              Removals & Storage
              </h2>
              <div className="-text-container">
                <p className="text-justify">
                  Welcome to Jansons Group, your trusted partner for all your
                  removal and relocation needs. We are proud to collaborate with
                  GetMovers, a reputable and professional moving company, to
                  offer you exceptional services through our website.
                </p>
                <p className="text-justify">
                  At Jansons Group, we understand that moving can be a daunting
                  and stressful task. That's why we have chosen to work with
                  GetMovers to provide you with reliable, efficient, and
                  hassle-free removal services. Whether you are planning a
                  residential move, an office relocation, or a commercial
                  transfer, we have got you covered.
                </p>
                <p className="text-justify">
                  GetMovers, through our website, brings years of experience and
                  expertise to the table. Their team of highly skilled
                  professionals is committed to ensuring a seamless moving
                  experience for every client. From packing and loading to
                  transportation and unpacking, they handle every aspect of your
                  move with utmost care and attention to detail.
                </p>
                <p className="text-justify">
                  Experience the difference of working with Jansons Group and
                  GetMovers for your removal needs. Visit our website to learn
                  more about our services, request a quote, or get in touch with
                  our friendly team. Let us make your next move a seamless and
                  enjoyable experience.
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className="text-center">
        <Container className="pt-5 pb-5">
          <Container className="text-center">
            <Row>
              <Col xs={12} sm={12} md={4} lg={4} xl={3} className="mb-3">
                <div
                  className="card"
                  style={{
                    border: "none",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <NavLink
                    to="https://getmovers.co.uk/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="card-img-top"
                      style={{
                        width: "16rem",
                        height: "16rem",
                        borderRadius: "10px",
                      }}
                      src={img1}
                      alt="Description of the image"
                    />
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        margin: "0",
                        padding: "0",
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        textAlign: "center",
                      }}
                    >
                      <h3
                        style={{
                          color: "white",
                          margin: "0",
                          padding: "0",
                          fontSize: "1.9rem" /* default font size */,
                          lineHeight: "2rem" /* default line height */,
                        }}
                      >
                        Get Movers
                      </h3>
                    </div>
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    </div>
  );
}

export default Removal;
