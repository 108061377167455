/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../img/logoo.png";
import { Dropdown } from "react-bootstrap";
import "./Navbar.css";

function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [showAboutDropdown, setShowAboutDropdown] = useState(false);
  const [showBusinessDropdown, setShowBusinessDropdown] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };
  const handleAboutMouseEnter = () => {
    setShowAboutDropdown(true);
  };

  const handleAboutMouseLeave = () => {
    setShowAboutDropdown(false);
  };

  const handleBusinessMouseEnter = () => setShowBusinessDropdown(true);
  const handleBusinessMouseLeave = () => setShowBusinessDropdown(false);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={logo} />
        </Link>
        <button
          className="navbar-toggler mr-3"
          type="button"
          onClick={handleNavToggle}
        >
          <span
            className="navbar-toggler-icon"
            style={{ marginRight: "8px" }}
          ></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav ml-auto" style={{ fontWeight: "bold" }}>
            <Dropdown
              show={showAboutDropdown}
              onMouseEnter={handleAboutMouseEnter}
              onMouseLeave={handleAboutMouseLeave}
            >
              <Dropdown.Toggle
                as={NavLink}
                className="nav-link"
                style={{ color: "#00acdd" }}
              >
                About Us
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ background: "#e9e7e7" }}>
                <Dropdown.Item
                  as={NavLink}
                  to="/about-the-group"
                  className="dropdown-link"
                >
                  About The Group
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to="/mission-and-vision"
                  className="dropdown-link"
                >
                  Mission & Vision
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to="/value-and-culture"
                  className="dropdown-link"
                >
                  Value & Culture
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              show={showBusinessDropdown}
              onMouseEnter={handleBusinessMouseEnter}
              onMouseLeave={handleBusinessMouseLeave}
            >
              <Dropdown.Toggle
                as={NavLink}
                className="nav-link"
                style={{ paddingBottom: "10px", color: "#00acdd" }}
              >
                Business
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ background: "#e9e7e7" }}>
                <Dropdown.Item
                  as={NavLink}
                  to="/property-development"
                  className="dropdown-link"
                >
                  Property & Land
                </Dropdown.Item>

                <Dropdown.Item
                  as={NavLink}
                  to="/travel-and-tourism"
                  className="dropdown-link"
                >
                  Travel & Tourism
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to="/legal-consultancy"
                  className="dropdown-link"
                >
                  Legal Consultancy
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to="/removal"
                  className="dropdown-link"
                >
                   Removal Company
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to="/digital-media"
                  className="dropdown-link"
                >
                 Digital Media
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to="/legal-foundation-for-overseas-pakistani"
                  className="dropdown-link"
                >
                  Legal Foundation
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to="/Business-Process-Outsourcing"
                  className="dropdown-link"
                >
                  Business Process
                </Dropdown.Item>
                <Dropdown.Item
                  as={NavLink}
                  to="/overseas-pakistanis-legal-services-international"
                  className="dropdown-link"
                >
                  Pakistanis Legal Services
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <NavLink
              className="nav-link"
              activeClassName="active"
              to="/media"
              style={{ color: "#00acdd" }}
            >
              Media
            </NavLink>

            <NavLink
              className="nav-link"
              activeClassName="active"
              to="/carrer"
              style={{ color: "#00acdd" }}
            >
              Careers
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
